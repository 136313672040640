import React from "react";
import 'semantic-ui-css/semantic.min.css';
import Layout from "../components/layout";
import { siteSubHeaderText, siteHeaderText, siteBodyText } from '../styles';
import { Item } from "semantic-ui-react";

const PrivacyPage = () => {
    return (
        <Layout>
            <Item.Group>
                <Item>
                    <Item.Content>
                        <Item.Header className={siteHeaderText}>Privacy Policy</Item.Header>
                        <Item.Description className={siteBodyText}>This site has no trackers. We value your privacy (and ours as well) and don't gather or share any information about visitors. </Item.Description>
                    </Item.Content>
                </Item>
            </Item.Group>

        </Layout>
    );
}

export default PrivacyPage;
